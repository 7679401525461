import React from 'react';

const HomeBlurb: React.FC = () => (
	<>
		<p className='lg:text-center text-sm lg:text-base my-3 lg:my-4'>
			Our Pomona is a crowd-sourced database of useful information on
			apples, pears and other pome fruits.
		</p>
		<p className='lg:text-center text-sm lg:text-base my-3 lg:my-4'>
			Use information provided by you and your peers to help decide what
			to plant, how to grow it, when to pick it, and how to ferment it.
		</p>
		<p className='lg:text-center text-sm lg:text-base my-3 lg:my-4'>
			Everyone is encouraged to contribute, whether you are a fruit
			grower, a cidermaker, or a drinker and enthusiast.
		</p>
		<p className='lg:text-center text-sm lg:text-base my-3 lg:my-4'>
			Our Pomona is here to help inform everyone connected to cider and
			perry, and we're interested in how the fruit grows, how it ferments,
			and what it tastes like when it's ready to drink.
		</p>
		<p className='lg:text-center text-sm lg:text-base my-3 lg:my-4'>
			Cheers!
		</p>
	</>
);

export default HomeBlurb;
