import React from 'react';
import { NavLink } from 'react-router-dom';
import rossImg from './../assets/images/ross_on_wye.jpg';

const HomeSponsors: React.FC = () => (
	<>
		<h5 className='font-bold text-center mt-6'>
			Our Pomona would like to thank
		</h5>
		<div className='flex flex-row mt-10 justify-around '>
			<div className='mx-3 p-2 w-32'>
				<a href='https://rosscider.com/'>
					<img
						className='object-contain'
						src={rossImg}
						alt='Ross On Wye Cider & Perry Company'
					/>
					<span className='text-xs'>
						Ross On Wye Cider & Perry Company
					</span>
				</a>
			</div>
			<div className='mx-3 p-2 bg-gray-100 inline-flex items-center flex-col justify-center text-xs text-center'>
				<NavLink to='/sponsor'>
					Click here <br />
					to sponsor
					<br />
					Our Pomona
				</NavLink>
			</div>
		</div>
	</>
);

export default HomeSponsors;
