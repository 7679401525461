import React, { useEffect, useState } from 'react';
import { VarietyCounts } from '../../common/server_responses/variety_counts/variety_counts';
import SERVER_ROUTES from '../../common/server_routes/server_routes';
import getFromServer from '../shared/get_from_server';
import LoadingIndicator from '../shared/loading/loading_indicator';

const VarietyCountsDisplay: React.FC = () => {
	const abortController = new AbortController();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [counts, setCounts] = useState<VarietyCounts>({
		total: NaN,
		approved: NaN,
	});

	useEffect(() => {
		const doFetch = async function () {
			setIsLoading(true);
			const response = await getFromServer<VarietyCounts>(
				SERVER_ROUTES.FetchVarietyCounts,
				null,
				abortController
			);

			if (response.success && response.result) {
				setCounts(response.result);
			}

			setIsLoading(false);
		};

		doFetch();

		return () => {
			abortController.abort();
		};
		//eslint-disable-next-line
	}, []);

	return (
		<div className='my-2 lg:text-center text-sm lg:text-base'>
			<LoadingIndicator loading={isLoading} />
			{/* {!isLoading && isNaN(counts.total) && (
				<div>We have information on many varieties!</div>
			)} */}
			{!isLoading && !isNaN(counts.total) && (
				<div>
					Right now, we have information on{' '}
					<strong>{counts.total}</strong>{' '}
					{Number(counts.total) === 1 ? 'variety' : 'varieties'} of
					pome fruits.
				</div>
			)}
		</div>
	);
};

export default VarietyCountsDisplay;
