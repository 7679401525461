import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { VarietySummary } from '../../common/server_responses/variety_summary/variety_summary';
import SERVER_ROUTES from '../../common/server_routes/server_routes';
import { capitalizeFirstLetter } from '../../common/utils/utils';
import HistorySnippet from '../shared/components/history_snippet';
import getFromServer from '../shared/get_from_server';
import VarietyTypeIcon from '../variety_detail/variety_header/variety_type_icon';

import noImagesImg from './../assets/images/default_fruit.png';

const FeaturedVarietyDisplay: React.FC = () => {
	const abortController = new AbortController();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [variety, setVariety] = useState<VarietySummary | undefined>(
		undefined
	);

	useEffect(() => {
		const doFetch = async function () {
			setIsLoading(true);
			const response = await getFromServer<VarietySummary>(
				SERVER_ROUTES.FetchFeaturedVariety,
				null,
				abortController
			);

			if (response.success && response.result) {
				setVariety(response.result);
			}

			setIsLoading(false);
		};

		doFetch();
		return () => {
			abortController.abort();
		};
		//eslint-disable-next-line
	}, []);

	return (
		<div
			className='py-1 px-1 mt-6 lg:px-6 flex flex-col items-center justify-center self-center text-center md:max-w-xs '
			style={{ minWidth: '20rem', minHeight: '20rem' }}>
			{!isLoading && variety && (
				<NavLink
					to={`/variety/${variety.id}`}
					className='flex flex-col align-middle items-center justify-center'>
					<h4 className='text-sm lg:text-lg font-serif break-all'>
						<span
							className='text-xs mr-4'
							style={{ position: 'relative', top: '-2px' }}>
							<VarietyTypeIcon type={variety.type} />
						</span>
						{capitalizeFirstLetter(variety.name)}
					</h4>
					<img
						className='w-48 my-2'
						src={variety.imageUrl || noImagesImg}
						alt={`${variety.name}`}
					/>
					<div
						className='text-xs lg:text-sm mt-1 px-3 lg:px-6 pt-3 pb-3 lg:pb-6 break-words'
						style={{ maxWidth: '70%' }}>
						<HistorySnippet history={variety.history} />
					</div>
				</NavLink>
			)}
		</div>
	);
};

export default FeaturedVarietyDisplay;
