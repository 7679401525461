import ROUTES from '../routes/client_routes';
import NavLinkButton from '../shared/components/navlink_button';

const HomeButtons: React.FC = () => (
	<div className='flex flex-col lg:flex-row justify-evenly items-center text-center mt-3'>
		<NavLinkButton to={ROUTES.BROWSE} text='Browse Pomona' size='text-xs' />

		<NavLinkButton
			to={ROUTES.ADD_VARIETY}
			text='Add to Pomona'
			size='text-xs'
		/>
	</div>
);

export default HomeButtons;
