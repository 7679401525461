import React from 'react';
import { Helmet } from 'react-helmet-async';
import FeaturedVariety from './featured_variety_display';
import HomeBlurb from './home_blurb';
import HomeButtons from './home_buttons';
import HomeSponsors from './home_sponsors';
import HomeTitle from './home_title';

import VarietyCounts from './variety_counts_display';

const Home: React.FC = () => (
	<>
		<Helmet>
			<title>Our Pomona</title>
		</Helmet>
		<section className='flex flex-col'>
			<div className='flex flex-col xl:flex-row justify-around items-stretch my-3'>
				<div className='mx-3 '>
					<HomeTitle />
					<HomeBlurb />
					<VarietyCounts />
					<HomeButtons />
				</div>
				<FeaturedVariety />
			</div>

			<hr className='mt-3 lg:mt-12' />
			<HomeSponsors />
		</section>
	</>
);

export default Home;
