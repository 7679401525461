import React from 'react';
import { NavLink } from 'react-router-dom';

export interface NavLinkButtonProps {
	text: string;
	to: string;
	size?: 'text-xs' | 'text-sm' | 'text-lg' | 'text-xl';
}

const NavLinkButton: React.FC<NavLinkButtonProps> = ({ text, to, size }) => (
	<NavLink to={to}>
		<button className={'button mt-3 lg:mt-0 lg:p-3 ' + size}>{text}</button>
	</NavLink>
);

export default NavLinkButton;
